import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import {ReactComponent as Reactlogo} from 'media/logo.svg';

/*eslint-disable react/prop-types */
const Navbar = () => {

        const [scrolled,setScrolled] = React.useState(false);

        const handleScroll=() => {
            const offset=window.scrollY;
            if(offset > 60 ){
                setScrolled(true);
            }
            else{
                setScrolled(false);
            }
        }

        useEffect(() => {
            window.addEventListener('scroll',handleScroll)
        })

        let x = ['navbar'];
        if(scrolled){
            x.push('sticky');
        }
        return (
            <nav className={x.join(" ")}>
                <NavHashLink
                    exact
                    to="/#"
                    isActive={() => {
                        return window.location.hash === '#profil';
                    }}
                >
                    <Reactlogo />
                </NavHashLink>
                <ul>
                    <li>
                        <NavHashLink
                            exact
                            to="/#profil"
                            isActive={() => {
                                return window.location.hash === '#profil';
                        }}
                        >
                            Profil
                        </NavHashLink>
                    </li>
                    <li>
                        <NavHashLink
                            exact
                            to="/#experiences"
                            isActive={() => {
                                return window.location.hash === '#experiences';
                            }}
                        >
                            Expériences
                        </NavHashLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to="/projets"
                        >
                            Projets
                        </NavLink>
                    </li>
                </ul>
            </nav>
        )
}

export default Navbar;
