import React, { Component } from 'react';
import { projectData } from 'app/data';
import {ProjectItem} from 'app/projects/index';


/*eslint-disable react/prop-types */
class SelectedProjectsList extends Component {
    render() {
        const filteredResults = projectData.filter((project) => {
            return project.home_selected === true;
        });

        const result = filteredResults.map((project) =>
            <ProjectItem data={project} key={project.folder} />
        )
        return (
            <div className="projects container" id="projets">
                <h2>Projets</h2>
                <div className="projects-list">
                    {result}
                </div>
            </div>
        );
    }
}

export default SelectedProjectsList;
