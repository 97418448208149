// import React, {Fragment} from 'react';
import React, {Fragment, Component} from 'react';
import { projectData } from 'app/data';
import Navbar from 'app/navbar';
import Footer from 'app/footer';
import { useRouteMatch } from "react-router-dom";
import { importImg } from 'app/projects/index'

/*eslint-disable react/prop-types */
// function importImg(r) {
//     let images = {};
//     r.keys().map((item) => {
//         images[item.replace('./', '').replace(/\.[a-z]+$/, '')] = r(item);
//     });

//     return images;
// }

function ProjectShow() {
    const match = useRouteMatch("/projets/:slug");
    const currentSlug = match.params.slug;
    const currentProjects = projectData.filter((project) => {
        return currentSlug === project.slug
    });

    const currentProject = currentProjects[0];


    return (
        <Fragment>
            <Navbar />
            <main>
                <ProjectsDetails project={currentProject} />
            </main>
            <Footer />
        </Fragment>
    );
}

class ProjectsDetails extends Component {
    render() {
        const project = this.props.project;
        const rolesList = project.roles.map((roles) => <li key={roles}>{roles}</li>);
        const categories = project.categories.map((category) => <Categorie data={category} folder={project.folder} key={category.name} />);
        return (
            <Fragment>
                <div className="project container">
                    <h1>{project.name}</h1>
                    <div className="tagline">{project.tag}</div>
                    <Image fileName="main" folder={project.folder} key={project.slug} />
                    <h2>Role</h2>
                    <ul>{rolesList}</ul>
                </div>
                {categories}
            </Fragment>
        );
    }
}

class Categorie extends Component {
    render() {
        const category = this.props.data;
        const images = category.images.map((img) => <ImageWithTitleAndDesc data={img} folder={this.props.folder} key={img.title} />);
        return (
            <div className="container">
                <h2>{category.name}</h2>
                <p>{category.text}</p>
                {images}
            </div>
        );
    }
}

class Image extends Component {
    render() {
        const images = importImg(require.context('media/projects', true));
        const folder = this.props.folder;
        const src =  folder + '/' + this.props.fileName;
        const source = images[src].default;

        return (
            <Fragment>
                <img className="img-fluid" src={source} alt={this.props.fileName} />
            </Fragment>
        )
    }
}

class ImageWithTitleAndDesc extends Component {
    render() {
        const images = importImg(require.context('media/projects', true));
        const folder = this.props.folder;
        const img = this.props.data;
        const src =  folder + '/' + img.img_name;
        const source = images[src].default;

        return (
            <Fragment>
                {img.title !== '' && img.title !== undefined &&
                    <div className="lead">{img.title}</div>
                }
                {img.desc !== '' && img.desc !== undefined &&
                    <p>{img.desc}</p>
                }
                <img className="img-fluid" src={source} alt={img.title} />
            </Fragment>
        )
    }
}



export default ProjectShow;

