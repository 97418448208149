import React, {Component, Fragment} from 'react';
import portrait from 'media/portrait.jpg';
import portrait2x from 'media/portrait@2x.jpg';
import webdesign from 'media/ico-webdesign.svg';
import development from 'media/ico-dev.svg';
import StarRatings from 'react-star-ratings';
import {profilData} from 'app/data';

function getAge() {
    const dob = '19850111';
    const year = Number(dob.substr(0, 4));
    const month = Number(dob.substr(4, 2)) - 1;
    const day = Number(dob.substr(6, 2));
    const today = new Date();
    let age = today.getFullYear() - year;
    if (today.getMonth() < month || (today.getMonth() == month && today.getDate() < day)){
      age--;
    }
    return age;
}

/*eslint-disable react/prop-types */
class Profil extends Component {
    render() {
        return (
            <div className="profil container" id="profil">
                <h2>
                    Profil
                    <div>Dédié front end</div>
                </h2>
                <Card />
                <Skills skills={profilData} />
            </div>
        );
    }
}

class Card extends Component {
    render() {
        return (
            <div className="card">
                <img src={portrait} srcSet={portrait2x} width="166" height="166"  alt="Brian Chevolleau" />
                <address>
                    <strong>Nom</strong> : Chevolleau Brian<br/>
                    <strong>Adresse</strong> : Nantes<br/>
                    <strong>Age</strong> : {getAge()}<br/>
                </address>
            </div>
        );
    }
}

class Skills extends Component {
    constructor(props){
        super(props);
    }

    render() {

        return (
            <div className="skills">
                <Skill name={this.props.skills.webdesign}/>
                <Skill name={this.props.skills.development}/>
            </div>
        );
    }
}

class Skill extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const skill = this.props.name;
        const img = skill.name === 'Webdesign' ? webdesign : development;
        return (
            <div className="skill">
                <img src={img} alt="" />
                <h3>{skill.name}</h3>
                <p>{skill.desc}</p>
                <Categories items={skill.category} />
            </div>
        );
    }
}

class Categories extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const categories = this.props.items
        const row = categories.map((item) =>
            <div className="list" key={item.name}>
                <h4>{item.name}</h4>
                <List list={item}/>
            </div>
        );

        return (
            <Fragment>
                {row}
            </Fragment>

        );
    }
}

class List extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const array = this.props.list.items.map((item) =>
            <ListItem name={item.name} rate={item.rate} key={item.name} />
        )

        return (
            <Fragment>{array}</Fragment>
        );
    }
}

class ListItem extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const primary = getComputedStyle(document.documentElement).getPropertyValue('--bs-primary');
        return (
            <ul>
                <li>
                    {this.props.name}
                    <StarRatings
                        rating={this.props.rate}
                        starDimension="15px"
                        starSpacing="2px"
                        starRatedColor={primary}
                        // starEmptyColor={}
                    />
                </li>
            </ul>
        );
    }
}

export default Profil;
