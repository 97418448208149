import React, {Fragment} from 'react';
import Navbar from 'app/navbar';
import Footer from 'app/footer';

/*eslint-disable react/prop-types */
function NoMatch() {
    return (
        <Fragment>
            <Navbar />
            <main>
                <div className="hero container">
                    <h1>Page introuvable</h1>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
}

export default NoMatch;
