import React, {Fragment} from 'react';
import Hero from './hero';
import Profil from './profil';
import Resume from './resume';
import Projects from './selected-projects';
import Navbar from 'app/navbar';
import Footer from 'app/footer';

/*eslint-disable react/prop-types */
function Home() {
    return (
        <Fragment>
            <Navbar />
            <main>
                <Hero />
                <Profil />
                <Resume />
                <Projects />
            </main>
            <Footer />
        </Fragment>
    );
}

export default Home;
