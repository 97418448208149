import React, {Fragment, Component} from 'react';
import Navbar from 'app/navbar';
import Footer from 'app/footer';
import { projectData } from 'app/data';
import { Link } from "react-router-dom";

/*eslint-disable react/prop-types */
function importImg(r) {
    let images = {};
    r.keys().map((item) => {
        images[item.replace('./', '').replace(/\.[a-z]+$/, '')] = r(item);
    });

    return images;
}

function ProjectsIndex() {
    return (
        <Fragment>
            <Navbar />
            <main>
                <ProjectsList />
            </main>
            <Footer />
        </Fragment>
    );
}

class ProjectsList extends Component {
    render() {
        const result = projectData.map((project) =>
            <ProjectItem data={project} key={project.folder} />
        )
        return (
            <div className="projects container" id="projets">
                <h1>Projets</h1>
                <div className="projects-list">
                    {result}
                </div>
            </div>
        );
    }
}

class ProjectItem extends Component {
    render() {
        const images = importImg(require.context('media/projects', true));
        const imagName = `${this.props.data.folder}/thumbnail`;
        const imagName2x = `${this.props.data.folder}/thumbnail@2x`;
        const src = images[imagName].default;
        const srcset = images[imagName2x].default;
        const slug = this.props.data.slug;

        return (
            <Link
                to={`/projets/${slug}`}
                className="project"
            >
                <div className="title">
                    <h3>
                        {this.props.data.name}
                        <div>
                            {this.props.data.tag}
                        </div>
                    </h3>
                </div>
                {<img src={src} srcSet={srcset} alt={imagName} />}
            </Link>
        );
    }
}

export default ProjectsIndex;
export {ProjectItem, importImg};
