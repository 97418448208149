const profilData = {
    webdesign: {
        name: 'Webdesign',
        desc: 'J’apprécie une structure de contenu simple, des modèles de conception propres et des interactions réfléchies.',
        category: [{
                name: 'Languages',
                items: [
                    { name: 'UX, UI, Web, Mobile, Apps, Logos' },
                ]
            },
            {
                name: 'Outils',
                items: [
                    { name: 'Figma', rate: 5 },
                    { name: 'Adobe XD', rate: 5 },
                    { name: 'Adobe Illustrator', rate: 5 },
                    { name: 'Adobe Photoshop', rate: 4 },
                    { name: 'Adobe InDesign', rate: 3 },
                    { name: 'Balsamiq Mockups', rate: 3 },
                ]
            },
        ]
    },
    development: {
        name: 'Front development',
        desc: 'J’aime coder à partir de zéro et donner vie à des idées dans le navigateur.',
        category: [{
                name: 'Languages',
                items: [
                    { name: 'HTML', rate: 5 },
                    { name: 'SCSS / SASS', rate: 5 },
                    { name: 'Javascript', rate: 5 },
                    { name: 'PHP', rate: 4 },
                    { name: 'SQL ', rate: 3 },
                ]
            },
            {
                name: 'Framework',
                items: [
                    { name: 'React', rate: 4 },
                    { name: 'Symfony', rate: 5 },
                    { name: 'Wordpress', rate: 4 },
                ]
            },
            {
                name: 'Outils',
                items: [
                    { name: 'Jira / Confluence', rate: 5 },
                    { name: 'Git', rate: 5 },
                    { name: 'Sublime text', rate: 5 },
                    { name: 'Iterm (Bash / Zsh)', rate: 4 },
                ]
            },
            {
                name: 'Librairies',
                items: [
                    { name: 'Bootstrap', rate: 5 },
                    { name: 'Webpack', rate: 4 },
                    { name: 'Twig', rate: 4 },
                    { name: 'Eslint', rate: 5 },
                    { name: 'Stylelint ', rate: 5 },
                    { name: 'ElasticSeach', rate: 2 },
                    { name: 'Tinymce', rate: 4 },
                    { name: 'Yarn', rate: 5 },
                    { name: 'Homebrew', rate: 4 },
                    { name: 'Composer', rate: 4 },
                ]
            },
        ]
    }
}

//TODO slug to slugify from name

const projectData = [
    {
        slug: "coaching-orientation",
        name: "Coaching d'orientation",
        folder: "coaching",
        tag: "Branding, Website",
        home_selected: true,
        roles: [
            "Branding",
            "UX/UI",
            "Development"
        ],
        categories: [
            {
                name: "Overview",
                text: "Coaching est un service spécialisé dans l’orientation, la recherche de formations et la gestion de carrière. Il propose des accompagnements sur-mesure pour les collégiens, lycéens, étudiants, salarié, pour les aider à trouver leur voie scolaire et professionnelle.",
                images: [
                    {
                        title: "Fiche produit",
                        img_name: "tablet"
                    },
                    {
                        img_name: "laptop-home"
                    },
                    {
                        title: "Image intro 1",
                        desc: "description 1",
                        img_name: "product-focus"
                    },
                ]
            },
            {
                name: "Branding",
                text: "Nous avions besoin de présenter ce service sous la forme de pack, bilan d'orientation en fonction de la cible, tout en offrant la possibilité tester le service avec 1h de bilan.",
                images: [
                    {
                        title: "Recherche thématique",
                        desc: "Psycologie, coaching, croissance, accompagnement, stabilité",
                        img_name: "planche-logo"
                    },
                    {
                        img_name: "logo"
                    },
                    {
                        title: "Couleurs",
                        img_name: "color"
                    },
                ]
            },
            {
                name: "Conception d'interface - UI",
                images: [
                    {
                        img_name: "product-index"
                    },
                    {
                        title: "Prototypage",
                        img_name: "proto"
                    },
                ]
            },
        ]
    },
    {
        slug: "cogedim-immobilier",
        name: "Cogedim immobilier",
        folder: "cogedim",
        tag: "Landing",
        home_selected: true
    },
    {
        slug: "diplomeo",
        name: "Diplomeo",
        folder: "diplomeo",
        tag: "Website",
        home_selected: true,
        roles: [
            "UX/UI",
            "Development"
        ],
        categories: [
            {
                name: "Overview",
                text: "Diplomeo est un moteur de recherche et comparateur de formations des études supérieures en France",
                images: [
                    {
                        title: "Fiche produit",
                        img_name: "android-school"
                    },
                    {
                        img_name: "animation"
                    },
                    {
                        img_name: "macbook-training"
                    },
                    {
                        img_name: "macbook-list"
                    },
                    {
                        title: "Image intro 1",
                        desc: "description 1",
                        img_name: "macbook-home"
                    },
                ]
            },
            {
                name: "Branding",
                images: [
                    {
                        title: "Logotype",
                        desc: "Changement, développement, transition",
                        img_name: "logo"
                    },
                    {
                        desc: "Démontrer à la fois la capacité de Diplomeo à accompagner les jeunes et sa proximité avec eux.",
                        img_name: "social-share"
                    },
                ]
            },
            {
                name: "Conception d'interface - UI",
                images: [
                    {
                        title: "Prototypage de SERP de resultats",
                        img_name: "proto"
                    },
                ]
            }
        ]
    },
    {
        slug: "off-tv",
        name: "Off.tv",
        folder: "offtv",
        tag: "Branding, Website",
        home_selected: false
    },
    {
        slug: "somfy",
        name: "Somfy tahoma",
        folder: "somfy",
        tag: "Landing",
        home_selected: false
    },
    {
        slug: "universal",
        name: "App Universal",
        folder: "universal",
        tag: "App",
        home_selected: true
    }
]

const resumeData = [
    {
        "company": {
            "name": "Diplomeo.com",
            "tagline": "Orientation étudiante",
            "date": "2016-*"
        },
        "job": {
            "title": "Developpeur front end - Ui / Ux",
            "desc": [
                "Prototypage",
                "Intégration",
                "Tracking",
                "SEO",
                "AB Test",
                "Webperf",
                "Mailing",
            ]
        }
    },
    {
        "company": {
            "name": "Baseandco.com",
            "tagline": "Agence leads à la perf.",
            "date": "2015-2016"
        },
        "job": {
            "title": "Web designer / intégrateur",
            "desc": [
                "Création / Intégration Mailing + Landing page de recutement",
                "Création de support de communication Print et Web",
                "Tracking management via outil propriétaire Baztrack.com"
            ]
        }
    },
    {
        "company": {
            "name": "Autoreflex.com",
            "tagline": "Petite annonce auto. - Groupe Mondadori",
            "date": "2014-2015"
        },
        "job": {
            "title": "Web designer / intégrateur",
            "desc": [
                "tbd",
            ]
        }
    },
    {
        "company": {
            "name": "Universal Music France",
            "tagline": "Labels de musique",
            "date": "2011-2014"
        },
        "job": {
            "title": "Web designer / intégrateur",
            "desc": [
                "Réalisation boutique e-commerce universal-music-store.fr via Foundation",
                "Création / Intégration / Support tech. template Emailing Labels - l’Olympia",
                "Création template Wordpress sites artistes",
                "Réalisation app Android Universal Music mobile",
            ]
        }
    },
    {
        "company": {
            "name": "Metropolitan-Influence",
            "tagline": "Agence de Com.",
            "date": "2010-2011"
        },
        "job": {
            "title": "Concepteur web",
            "desc": [
                "Réalisation d’emailings créa / intégration / routage",
                "Gestion site web sous Drupal / Wordpress",
                "Réalisation de maquettes Print",
            ]
        }
    },
    {
        "company": {
            "name": "Phonevalley",
            "tagline": "Application mobile - Groupe Publicis",
            "date": "2009"
        },
        "job": {
            "title": "Mobile designer / intégrateur",
            "desc": [
                "Création / Intégration d’applications mobiles - Production de Storyboard chartés",
                "Ref : Amaguiz -Buick -Cadillac -Cartier-Crunch -Diet Coca -GMC-Nissan - Pantene - etc.",
            ]
        }
    }
]

export {
    profilData,
    projectData,
    resumeData,
};
