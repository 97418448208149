import React from 'react';
import Routing from 'app/routing';

/*eslint-disable react/prop-types */
function App() {
    return (
        <Routing />
    );
}
export default App;
