import React from 'react';
import Home from './home/index';
import NoMatch from 'app/errors';
import ProjectsIndex from './projects/index';
import ProjectShow from './projects/show';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';

const routes = [{
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: "/projets",
        exact: true,
        component: ProjectsIndex,
    },
    {
        path: "/projets/:slug",
        exact: true,
        component: ProjectShow
    }
];

const redirects = [{
        path: "/portfolio",
        to: "/projets"
    }
];

const errors = [
    // 404
    {
        path: "*",
        component: NoMatch
    },
];


/*eslint-disable react/prop-types */
function Routing() {
    return (
        <DebugRouter>
            <Switch>
                {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
                {redirects.map((redirect, i) => (
                    <RedirectRoute key={i} {...redirect} />
                ))}
                {errors.map((error, i) => (
                    <RouteWithSubRoutes key={i} {...error} />
                ))}
            </Switch>
        </DebugRouter>
    );
}

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}

function RedirectRoute(redirect) {
    return (
        <Route path={redirect.path} >
            <Redirect to={redirect.to} />
        </Route>
    );
}

class DebugRouter extends Router {
    constructor(props) {
        super(props);
        console.log('initial history is: ', JSON.stringify(this.history, null, 2))
        this.history.listen((location, action) => {
            console.log(
                `The current URL is ${location.pathname}${location.search}${location.hash}`
            )
            console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
        });
    }
}

export default Routing;
