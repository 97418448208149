import React, { Component } from 'react';
import {ReactComponent as Facebook} from '../media/facebook.svg';
import {ReactComponent as Twitter} from '../media/twitter.svg';
import {ReactComponent as Instagram} from '../media/instagram.svg';

/*eslint-disable react/prop-types */

function Year() {
    const today = new Date();
    return today.getFullYear();
}

class Footer extends Component {
    render() {
        return (
            <footer className="container">
                <div className="social">
                    Retrouvez moi 
                    <Social>
                        <Facebook href="#"/>
                    </Social>
                    <Social>
                        <Twitter href="#"/>
                    </Social>
                    <Social>
                        <Instagram href="#"/>
                    </Social>
                </div>
                <div>© Chevolleau.net {Year()}</div>
            </footer>
        );
    }
}

class Social extends Component {
    render() {
        return (
            <a href={this.props.href}>
                {this.props.children}
            </a>
        );
    }
}

export default Footer;
