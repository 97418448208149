import React, {Component, Fragment} from 'react';
import {resumeData} from 'app/data';

/*eslint-disable react/prop-types */
class Resume extends Component {
    render() {
        return (
            <div className="resume container" id="experiences">
                <h2>Expériences</h2>
                <Jobs jobs={resumeData}/>
            </div>
        );
    }
}

class Jobs extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const jobs = this.props.jobs.map((job) =>
            <div className="row" key={job.company.name}>
                <Company content={job.company} />
                <Job content={job.job} />
            </div>
        )

        return (
            <Fragment>
                {jobs}
            </Fragment>
        );
    }
}

class Row extends Component {
    render() {
        return (
            <div className={this.props.class}>
                {this.props.children}
            </div>
        );
    }
}

class Company extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Row class="company">
                <div className="company-name">
                    {this.props.content.name}
                    <div>{this.props.content.tagline}</div>
                </div>
                <div className="duration">{this.props.content.date}</div>
            </Row>
        );
    }
}

class Job extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const listItems = this.props.content.desc.map((description) =>
            <li key={description}>
                {description}
            </li>
        );
        return (
            <Row class="job">
                <h3>{this.props.content.title}</h3>
                <ul>{listItems}</ul>
            </Row>
        );
    }
}

export default Resume;
