import React, {Component, Fragment} from 'react';
import visuel from 'media/visuel-top.svg';

class Hero extends Component {
    render() {
        return (
            <div className="hero container">
                <Content/>
            </div>
        );
    }
}

class Content extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <h1>
                        Brian Chevolleau
                        <div>
                            Développeur front end
                        </div>
                    </h1>
                </div>
                <img src={visuel} alt="" />
            </Fragment>
        );
    }
}

export default Hero;
